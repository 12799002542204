import _ from 'lodash';

//Import all the service definitions
import GOT_20230315 from '@/assets/services/GOT/20230315.json';

//VERSION ALWAYS HAS TO BE THIS DATE FORMAT YYYYMMDD
const SERVICES = {
  'GOT': [
    GOT_20230315
  ]
};

/**
 * Calculates the ends of all categories and recursive sub_categories by taking the start parameter if present and adding end (next start - 1).
 * Loops through all categories backwards to always have the start of the following category to calculate the end of the preceding category
 * 
 * When calling, serviceLimitForCategories needs to be adapted to be the last index (depending on if it starts with 0 or not).
 * It is the maximum end and has to be treated as such
 * 
 * Returns the mapped categories and the first start item of the first category
 */
export function calculateRanges(categories, serviceLimitForCategories) {
  if (Array.isArray(categories) && serviceLimitForCategories > 0) {
    //currentStart is the lowest start so far and will be the next end (-1) for the next time we calculate a range in the loop.
    //Initialized with the maximum for this category range (+1) (smaller with each sub_category level). In the end will be the total lowest start.
    let currentStart = serviceLimitForCategories + 1; //Add 1 to make it the "start" of the following category, as the following code subtracts 1
    let newCategories = [];
    for (let index = (categories.length - 1); index >= 0; index--) {
      //Take current category
      let currentCategory = categories[index];

      if (currentCategory != null) {
        //Create an empty new category object to save all the mapped values, omit the values that will be mapped
        let currentNewCategory = _.omit(currentCategory, ['items', 'categories', 'sub_headings', 'sub_categories']);

        //Go one recursion deeper if we encounter it, before processing the current items. sub_categories always come after the items of the current category
        //categories is used in the highest level of the sections - optional sub_headings below that - sub_categories below that. Use the correct one to process.
        let nextLevelKey;
        if ('categories' in currentCategory) nextLevelKey = 'categories';
        else if ('sub_headings' in currentCategory) nextLevelKey = 'sub_headings';
        else if ('sub_categories' in currentCategory) nextLevelKey = 'sub_categories';
        if (currentCategory[nextLevelKey] != null) {
          let subRanges = calculateRanges(currentCategory[nextLevelKey], (currentStart - 1));
          if (subRanges != null) {
            //The start of the sub_categories is either the end (-1) for the items of the currentCategory or the next category (and their sub_categories)
            currentStart = subRanges.start;
            currentNewCategory[nextLevelKey] = subRanges.mappedCategories;
          }
        }

        //If this category has item ranges set the correct start and end range according to the above logic
        if (currentCategory.items != null) {
          //currentStart - 1 is the end of this range, before it will be set to the new start value
          let newEnd = (currentStart - 1);
          //Save the start of its items to be the new end (-1) for the next category (preceding one) in the loop
          currentStart = currentCategory.items.start;

          currentNewCategory.items = { 
            'start': currentStart,
            'end': newEnd
          };
        }

        newCategories.push(currentNewCategory);
      }
    }

    return {
      start: currentStart,
      mappedCategories: newCategories.reverse()
    }
  }
}

function sortItemsByIdentifier(itemA, itemB) {
  if (itemA.identifier == null || itemB.identifier == null) return 0;
  return itemA.identifier - itemB.identifier;
}

/**
 * Takes the order from the categories and tries to place hierarchical elements in there. If the hierarchy level can't be found, adds it at the end of the current level.
 * Works recursively by taking the next level of categories and hierarchichalElements in every recursion step.
 */
export function orderHierarchicalElements(categories, hierarchicalElements) {
  if (Array.isArray(categories) && hierarchicalElements != null) {
    let newCategories = [];
    let visitedCategories = [];
    for (let currentCategory of categories) {
      if (currentCategory != null) {
        let descriptor = currentCategory.section || currentCategory.descriptor || currentCategory.name;
        visitedCategories.push(descriptor);
        let currentHierarchy = hierarchicalElements[descriptor];

        if (currentHierarchy != null) {
          //Create an empty new category object to save all the mapped values, omit the values that will be mapped
          let currentNewCategory = _.omit(currentCategory, ['items', 'categories', 'sub_headings', 'sub_categories']);

          //Go one recursion deeper if we encounter it, before processing the current items. sub_categories always come after the items of the current category
          //categories is used in the highest level of the sections - optional sub_headings below that - sub_categories below that. Use the correct one to process.
          let nextLevelKey;
          if ('categories' in currentCategory) nextLevelKey = 'categories';
          else if ('sub_headings' in currentCategory) nextLevelKey = 'sub_headings';
          else if ('sub_categories' in currentCategory) nextLevelKey = 'sub_categories';
          let isEmpty = true;
          if (currentCategory[nextLevelKey] != null) {
            let filteredSubCategories = orderHierarchicalElements(currentCategory[nextLevelKey], currentHierarchy[nextLevelKey]);
            if (filteredSubCategories != null && Array.isArray(filteredSubCategories) && filteredSubCategories.length > 0) {
              currentNewCategory[nextLevelKey] = filteredSubCategories;
              isEmpty = false;
            }
          }

          //If this category has items, sort and add them
          if (currentHierarchy.items != null) {
            currentNewCategory.items = currentHierarchy.items;
            if (currentNewCategory.items.instances != null) {
              currentNewCategory.items.instances.sort(sortItemsByIdentifier);
            }
            isEmpty = false;
          }

          //Ignore empty levels
          if (!isEmpty) newCategories.push(currentNewCategory);
        }
      }
    }

    //After visiting all categories, add the remaining ones without special processing, just take the values. Might be empty then no additional ones added
    return newCategories.concat(Object.values(_.omit(hierarchicalElements, visitedCategories)));
  }
}

export function getIdentifyingAttributes(item) {
  return _.pick(item, ['id', 'identifier', 'name']);
}

export function applyFunctionHierarchicallyToItems(categories, reducerFunction, reducerObject, startingLevel = 'section', currentLevels = [], pastHierarchy = {}) {
  if (Array.isArray(categories) && reducerFunction != null && reducerObject != null) {
    let reducedObject = reducerObject;

    let categoryIndex = 0;
    for (let currentCategory of categories) {
      if (currentCategory != null) {
        let descriptor = currentCategory.section || currentCategory.descriptor || currentCategory.name;
        
        let currentHierarchy = {...pastHierarchy, [startingLevel]: descriptor};

        //Go one recursion deeper if we encounter it, before processing the current items. sub_categories always come after the items of the current category
        //categories is used in the highest level of the sections - optional sub_headings below that - sub_categories below that. Use the correct one to process.
        let nextLevelKey;
        let nextLevelSingleDescriptor;
        if ('categories' in currentCategory) {
          nextLevelKey = 'categories';
          nextLevelSingleDescriptor = 'category';
        } else if ('sub_headings' in currentCategory) {
          nextLevelKey = 'sub_headings';
          nextLevelSingleDescriptor = 'sub_heading';
        } else if ('sub_categories' in currentCategory) {
          nextLevelKey = 'sub_categories';
          nextLevelSingleDescriptor = 'sub_category';
        }

        if (currentCategory[nextLevelKey] != null) {
          reducedObject = applyFunctionHierarchicallyToItems(currentCategory[nextLevelKey], reducerFunction, reducedObject, nextLevelSingleDescriptor, [...currentLevels, categoryIndex, nextLevelKey], currentHierarchy);
        }

        //If this category is not null, call reducer with it
        if (currentCategory != null) {
          reducedObject = reducerFunction(reducedObject, currentCategory, [...currentLevels, categoryIndex], currentHierarchy);
        }
      }

      categoryIndex++;
    }

    return reducedObject;
  }
}

export const CUSTOM_KEY = 'CUSTOM'; //TODO Sort custom items

export function transformListIntoHierarchicalOrder(elementList) {
  let resultingHierarchy = {};

  for (let element of elementList) {
    //Custom elements and ones without section / category are added under 'custom'
    if (element.section != null && element.category != null && !element.custom) {
      //Add at least the section and the category, if not yet added
      if (!(element.section in resultingHierarchy)) resultingHierarchy[element.section] = { categories: {} };
      if (!(element.category in resultingHierarchy[element.section].categories)) resultingHierarchy[element.section].categories[element.category] = {};
      //Save this as the current level. Going as deep as possible in the next steps and adding items at the lowest possible level
      let currentLevel = resultingHierarchy[element.section].categories[element.category];

      //First add optional sub_headings
      if (element.sub_heading != null) {
        if (!('sub_headings' in currentLevel)) currentLevel['sub_headings'] = {};
        if (!(element.sub_heading in currentLevel['sub_headings'])) currentLevel['sub_headings'][element.sub_heading] = {};
        currentLevel = currentLevel['sub_headings'][element.sub_heading];
      }
      //Then add optional sub_categories either in the sub_headings if it is set or in the categories
      if (element.sub_category != null) {
        if (!('sub_categories' in currentLevel)) currentLevel['sub_categories'] = {};
        if (!(element.sub_category in currentLevel['sub_categories'])) currentLevel['sub_categories'][element.sub_category] = {};
        currentLevel = currentLevel['sub_categories'][element.sub_category];
      }

      //Then add the items at the lowest level, either categories, sub_headings or sub_categories
      if (!('items' in currentLevel)) currentLevel['items'] = { instances: [] };

      currentLevel.items.instances.push(element);
    } else {
      //Add custom item if it didn't fit anywhere else. Just gets passed through, needs to contain section key and category inside an array.
      if (!(CUSTOM_KEY in resultingHierarchy)) resultingHierarchy[CUSTOM_KEY] = { section: CUSTOM_KEY, categories: [{ items: { instances: [] } }] };
      resultingHierarchy[CUSTOM_KEY].categories[0].items.instances.push(element);
    }
  }

  return resultingHierarchy;
}

export const serviceDefinitions = _.mapValues(SERVICES, (service_types) => {
  let mappedServices = _.map(service_types, (service) => {
    let mappedRanges = calculateRanges(service.categorization, Object.keys(service.services).length);

    return {
      version: service.version,
      descriptor: service.descriptor,
      services: service.services,
      categorization: (mappedRanges != null) ? mappedRanges.mappedCategories : undefined
    };
  });

  let versions = _.keyBy(mappedServices, 'version');
  //Get the newest version from the keys
  let newestVersion = Object.keys(versions).reduce((max, version) => version > max ? version : max);

  return {
    versions,
    newest: newestVersion,
    descriptor: versions[newestVersion].descriptor
  }
});

export function getServiceWithIdentifier(type, version, identifier) {
  if (serviceDefinitions != null && serviceDefinitions[type] != null && serviceDefinitions[type].versions != null && serviceDefinitions[type].versions[version] != null && 
  serviceDefinitions[type].versions[version].services != null && identifier in serviceDefinitions[type].versions[version].services) {
    return serviceDefinitions[type].versions[version].services[identifier];
  }
}

//Returns either the instances if given or a range of identifiers
export function getServiceItems(items, defaultType, defaultVersion) {
  if (items == null) return null;

  //Saves all instance objects of service items. If no instance is given, try to load it with the identifier
  let instances = [];

  if (items.instances != null) {
    instances = _.compact(_.map(items.instances, (item) => {
      //Create a shallow copy for modifications
      let itemCopy = {...item};

      //Try to get the instance with the identifier if it is not already set, and it is not a custom element
      if (itemCopy.instance == null) {
        if (!(itemCopy.custom) && itemCopy.type != null && itemCopy.version != null && itemCopy.identifier != null) {
          itemCopy.instance = getServiceWithIdentifier(itemCopy.type, itemCopy.version, itemCopy.identifier);
        } else if (itemCopy.name != null && itemCopy.value != null) {
          itemCopy.instance = _.pick(itemCopy, ['name', 'value', 'factor']);
        }
      }

      return itemCopy;
    }));
  } else if (items.end != null && items.start != null && defaultType != null && defaultVersion != null) { //Only get ranges if they can be resolved using the default type and version
    //Create instance objects from range of identifiers
    for (let identifierIndex = items.start; identifierIndex <= items.end; identifierIndex++) {
      instances.push({
        identifier: identifierIndex,
        instance: getServiceWithIdentifier(defaultType, defaultVersion, identifierIndex)
      });
    }
  }

  return instances;
}

const centsSeparatorRegex = /^(?<whole>.*?)([,.](?<cents>[0-9]{1,2}))?$/

export function parseCurrencyToCents(currencyValue) {
  if (currencyValue != null) {
    //Convert to string if it isn't already
    let currencyString = (_.isString(currencyValue)) ? currencyValue : currencyValue.toFixed(2);
    //Split currency at the last one to two digits preceded by a separatpr (cents value)
    let splitCurrency = currencyString.match(centsSeparatorRegex);
  
    if (splitCurrency != null && splitCurrency.groups != null) {
      //First remove everything in the whole part (without cents) that is not a digit
      let sanitizedWholeAmount = splitCurrency.groups.whole.replaceAll(/[^0-9]/g, '');
      //Then pad cents to be always 2 long for correct representation after concat, if it exists otherwise just 00 cents
      let paddedCentsAmount = (splitCurrency.groups.cents != null) ? splitCurrency.groups.cents.padEnd(2, '0') : '00';
  
  
      let totalCentsAmount = parseInt(`${sanitizedWholeAmount}${paddedCentsAmount}`);

      return totalCentsAmount;
    }
  }
}

//Supports number in cents (integer) or an arbitrary string that has to be parsed
export function scaleCurrencyValueByFactor(currencyValue, factor) {
  //Only parse if it is not a number
  let totalCentsAmount = (_.isNumber(currencyValue)) ? currencyValue : parseCurrencyToCents(currencyValue);

  let factorAmount = (factor > 0) ? factor : 1;

  if (totalCentsAmount != null) {
    let scaledCents = Math.round(totalCentsAmount * factorAmount);

    return scaledCents;
  }
}

//Creates data according to the EPC QR Standard for transferring money to other bank accounts
//Value is supposed to be a number!
export function generateEPCaccountData (bankAccountInformation, value, currency, referenceText) {
  if (bankAccountInformation != null && bankAccountInformation.recipient != null && bankAccountInformation.iban != null && value != null && currency != null) {
    //Format has to be like this to not include extra spaces
    let dataString = 
`BCD
002
1
SCT
${bankAccountInformation.bic || ''}
${bankAccountInformation.recipient}
${bankAccountInformation.iban}
${currency}${value.toFixed(2)}


${referenceText || ''}

`
    return dataString;
  }
}